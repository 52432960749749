// 引入需要的模块
import { createRouter,  createWebHistory } from 'vue-router'

// 下面使用了es6的对象增强写法，命名必须是routes
const routes = [
    {
        path: '/',
        redirect: ''
    },
    {
        path: '/',
        // 已经配置了路径别名，@/view 就可以写成 view
        // 配置了extensions，login.vue可以写成login
        component: () => import('@/views/index.vue')
    },
    {
        path: '/home',
        component: () => import('@/views/home.vue')
    },
    // {
    //     path: '/ThreeComponent',
    //     component: () => import('@/components/ThreeComponent.vue')
    // },
    // {
    //     path: '/test',
    //     component: () => import('@/views/test.vue')
    // },
    // {
    //     path: '/test_two',
    //     component: () => import('@/views/test_two.vue')
    // },
    // {
    //     path: '/msg',
    //     component: () => import('@/views/msg.vue')
    // },
    // {
    //     path: '/tea',
    //     component: () => import('@/views/tea.vue')
    // }
]

// 创建路由
const router = createRouter({
    history: createWebHistory(),
    routes,
    //启用parseHeaders选项
    // mode: 'history',
    // parseHeaders: true,
})

// 导出路由
export default router
